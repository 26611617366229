const dev = {
  s3: {
    BUCKET: "aplus.s3.amazonaws.com",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api-staging.belugahealth.com",
    // URL: "http://localhost:9000"
  },
};

const prod = {
  s3: {
    BUCKET: "stendraprod209.s3.amazonaws.com",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.belugahealth.com",
  },
};
// eslint-disable-next-line import/no-anonymous-default-export
export const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

// eslint-disable-next-line import/no-anonymous-default-export
export const apiKey =
  process.env.REACT_APP_STAGE === "prod"
    ? "UgmQPKV9HX3zQ2jTCUT2"
    : "6p2ucmzWrqMjtMeMqSRF";
