// Ozempic, Saxenda, and Rybelsus at all dosages
export const patientPreferenceOptions = [
  {
    medication: "Ozempic",
    options: [
      {
        name: "Ozempic 0.25mg",
        id: "6TCLeXzE2ZiQ7d2jVdBWBHzrdCOAlJsX",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "0.25mg",
      },
      {
        name: "Ozempic 0.5mg",
        id: "TUvIbpvehWzmajcpljRtBOlLKOd9Ksfs",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "0.5mg",
      },
      {
        name: "Ozempic 1mg",
        id: "QTjwsz8RFyGZjm0Y912v8nCRrCvmz8sl",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "1mg",
      },
    ],
  },
  {
    medication: "Saxenda",
    options: [
      {
        name: "Saxenda",
        id: "QUYoE53d4nNIxP85Y4XRgsYx9ejcm5Nz",
        quantity: 3,
        dispense: "ml",
        refills: 3,
        strength: "None",
      },
    ],
  },
  {
    medication: "Rybelsus",
    options: [
      {
        name: "Rybelsus 3mg",
        id: "oVJ1ceDCPrKlL8CJdPUekcDSxEAG8Qzo",
        quantity: 30,
        dispense: "tablets",
        refills: 0,
        strength: "3mg",
      },
      {
        name: "Rybelsus 7mg",
        id: "vkclzvpo6Ak71P9bocQNgS0N9THeCwif",
        quantity: 30,
        dispense: "tablets",
        refills: 0,
        strength: "7mg",
      },
      {
        name: "Rybelsus 14mg",
        id: "rWopjzc2M8TWjQ7wGHA4YEAKpaoVrxHd",
        quantity: 30,
        dispense: "tablets",
        refills: 0,
        strength: "14mg",
      },
    ],
  },
  {
    medication: "Wegovy",
    options: [
      {
        name: "Wegovy 0.25mg",
        id: "yrbqXSVX9mrMpth91OEszx7bPGi4hMXH",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "0.25 mg/0.5 mL (0.25 mg dose)",
      },
      {
        name: "Wegovy 0.5mg",
        id: "fV5uaGx2IJ9VeQ0mxoclbYq4bgFZZSxO",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "0.5 mg/0.5 mL (0.5 mg dose)",
      },
      {
        name: "Wegovy 1mg",
        id: "NeEPvtesfrCakHkIhTpkJcihiyZHbyoA",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "1 mg/0.5 mL (1 mg dose)",
      },
      {
        name: "Wegovy 1.7mg",
        id: "eeSFJOtlyVrpfiKckXjnFwzEmUrtjXav",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "1.7 mg/0.75 mL (1.7 mg dose)",
      },

      {
        name: "Wegovy 2.4mg",
        id: "DzvnUxR8LalsvMsd9YuMFkP0I58C0Aog",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "2.4 mg/0.75 mL (2.4 mg dose)",
      },
    ],
  },
  {
    medication: "Mounjaro",
    options: [
      {
        name: "Mounjaro 2.5mg",
        id: "dDiQ5EpWuVrtInwmWMqDrYwvLMUMZpbu",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "2.5 mg/0.5 mL",
      },
      {
        name: "Mounjaro 5mg",
        id: "tdU7U0kcNeTzc1Lqbnu4nyJTRckGPq5k",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "5 mg/0.5 mL",
      },
      {
        name: "Mounjaro 7.5mg",
        id: "Yie6q5gUg2MszZLsSZ3tny3V8mqlDul3",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "7.5 mg/0.5 mL",
      },
      {
        name: "Mounjaro 10mg",
        id: "TWqCUs7KxPsXwetEKdWuQfnmZ4c13XSQ",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "10 mg/0.5 mL",
      },
      {
        name: "Mounjaro 12.5mg",
        id: "i2v09u191e6492O4aVg0dmSaiL0Ghmd9",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "12.5 mg/0.5 mL",
      },
      {
        name: "Mounjaro 15mg",
        id: "OovZC6RIApK3YUojXKjfnMmun9Bhdwx1",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "15 mg/0.5 mL",
      },
    ],
  },
];

const patientPreferenceChoices = patientPreferenceOptions.reduce(
  (prev, cur) => {
    const group = cur.medication;
    const options = cur.options;
    prev.push({
      answer: {
        isText: true,
        value: group,
      },
    });
    options.forEach((opt) => {
      prev.push({
        answer: {
          name: `${opt.name} - ${opt.quantity} ${opt.dispense} - (Refills - ${opt.refills})`,
          group,
          optionId: opt.id,
          optionData: opt,
        },
      });
    });
    return prev;
  },
  []
);

export const questionnaire = [
  {
    question: "Please Select Your preferred medication option",
    type: "radio",
    answers: patientPreferenceChoices,
    standardDemo: "patientPreference",
  },
  {
    question: "How are you doing on the current medication?",
    answers: [
      {
        answer: "Well",
        showConditional: false,
      },
      {
        answer: "Not well",
        showConditional: true,
      },
    ],
    type: "radio",
  },
  {
    question: "Please tell us more about what you're experiencing",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Current height",
    subText: "Height in inches",
    answers: ["number"],
    type: "number",
    standardDemo: "height",
    isConditional: false,
  },
  {
    question: "Current Weight",
    subText: "Weight in pounds (lbs)",
    answers: ["number"],
    type: "number",
    standardDemo: "weight",
    isConditional: false,
  },
  // {
  //   question: "BMI",
  //   answers: ["freeText"],
  //   type: "freeText",
  //   isConditional: false,
  // },
  {
    question: "Have you had any side effects from the current medication?",
    answers: [
      {
        answer: "No",
        showConditional: false,
      },
      {
        answer: "Yes",
        showConditional: true,
      },
    ],
    type: "radio",
  },
  {
    question: "Please tell us more about the side effects you are experiencing",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Are you ready to increase your dose or stay at the same dose?",
    answers: [
      {
        answer: "Increase",
        showConditional: false,
      },
      {
        answer: "Stay at the same dose",
        showConditional: false,
      },
      {
        answer: "Stop the medication",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question: "Anything else you'd like to share with your doctor?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: false,
  },
];
